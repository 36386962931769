import React from 'react';
import '../styles/global.scss';
import Layout from '../components/layout';
import CCLLeadspace from '../components/cclLeadspace';
import FinancialServicesStats from './partials/financialServicesStats';
import CardSlider from '../components/cardSlider';
import JourneyForm from '../components/journeyForm';
import CCLButton from '../components/cclButton';
import CCLExplore from '../components/cclExplore';
import '../styles/global.scss';
import LeadImage from '../images/financial-leadspace.jpg';
import SEO from '../components/seo';
import Story1 from '../images/stories/financial1.jpg';

let cards = [
  {
    header: 'Explore our stories of impact & our latest thinking',
    subheader: '',
    cards: [
      {
        title: 'Retaining Talent in Financial Services by Building a More Trusting Culture',
        link: 'https://www.ccl.org/articles/client-successes/retaining-talent-building-more-trusting-culture/',
        label: 'View Client Success'
      },
      {
        title: 'Managing Remote Employees',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/managing-remote-employees-how-to-lead-from-a-distance/',
        label: 'Read Article'
      },
      {
        title: 'COVID-19: Turning Crisis into Opportunity',
        link: 'https://www.ccl.org/articles/white-papers/turning-crisis-into-opportunity/',
        label: 'Read our POV'
      },
      {
        title: 'Critical Leadership Competencies in the Financial Services Industry',
        link: 'https://www.ccl.org/articles/white-papers/critical-competencies-in-finserv-industry/',
        label: 'Download White Paper'
      },
      {
        title: 'Multinational Insurance Firm Achieves Leadership Alignment on Business Strategy',
        link: 'https://www.ccl.org/articles/client-successes/fostering-leadership-across-multinational-enterprise/',
        label: 'View Client Success'
      },
      {
        title: 'Coaching to Improve Performance',
        link: 'https://www.ccl.org/articles/leading-effectively-articles/coaching-to-improve-performance/',
        label: 'Read Article'
      },
    ]
  }
];

const FinancialServices = () => (
  <Layout>
    <SEO
      title="Financial Services | Innovative Leadership"
      description="In an ever changing world, one constant fact remains: great leadership can turn challenge into opportunity. See how CCL has helped financial services organizations."
      meta={{
        imgAlt: 'A woman in an innovative leadership role intently focuses on transforming her career with CCL. '
      }}
      noFollowIndexed
    />
    <CCLLeadspace image={LeadImage} copy="EVOLVE.  ADAPT.  LEAD THROUGH DISRUPTION." backgroundAlginRight alt="Financial services executive looking to the future"/>
    <div className="black-text-box">
      <h2>In today’s world, the only thing that remains the same is change—and change is more rapid and complex than ever before. How can financial services organizations navigate the crisis of the current moment while increasing the bottom line and planning for the changed world of business that comes next? Agile and innovative leadership is no longer an asset, but an imperative.</h2>
      <h2>We’re the Center for Creative Leadership, and we’re here to guide you through this change – and the uncertain future that lies ahead.</h2>
    </div>
    <FinancialServicesStats />
    <div className="stories">
      <span>
        <CCLExplore
          className='story'
          label='FEATURED WEBINAR'
          description={<>How Financial Services Firms Can Stay Connected and Thrive with Better Conversations.<br/><br/>Date: Tuesday, July 14th<br/>Time: 11:00 AM - 12:00 PM EDT</>}
          image={Story1}
          link='https://cclwebinars.webvent.tv/webinar/3822'
          cta='register now'
          btnClass='primary'
          isFinancialCard />
      </span>
      <div className="description">
        <div className="wrapper">
          <div>Attend this webinar and learn:</div>
          <ul>
            <li>The 4 types of disruptions the financial services industry can expect to face in the future.</li>
            <li>How taking a systemic approach to better conversations can address these disruptions, build a strong organizational culture, and drive performance.</li>
            <li>Approaches taken by successful financial service firms to stay agile and prepare for a transformed world.</li>
          </ul>
        </div>
      </div>
    </div>
    
    <div className="testimonial-section">
      <div className="white-bg-quote-text">
        <h2 className="text-wrapper">
          “Working with CCL, we know which human factors or talent drivers to focus on to strengthen the individuals and the organization to meet our challenges. We are seeing a change in mindset — from leaders ready and willing to achieve short-term results to leaders ready and willing to step up and become agents of strategic organizational transformation.”
        </h2>
      </div>
      <div className="shape-divider">
        <div className="shape-left"></div>
        <div className="shape-right"></div>
      </div>
      <div className="name">—Ursula Alvarez, Head of Talent Management, Credicorp</div>
    </div>
    <CardSlider {...cards} />
    <JourneyForm formName="lns-finsrv" interestCode="FORMVERTCONTACTFINSERVLNS" description="The world is changing. Your industry is transforming. Your leaders are ready to step up. Let’s keep the momentum going. Contact CCL to talk more about your needs." btnTxt="REQUEST CONSULTATION"/>
  </Layout>
);

export default FinancialServices;
